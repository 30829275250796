<template>
  <div class="ProjectsClients">
    <v-container class="px-8 px-sm-12 px-md-3 py-0">
      <v-row
        v-for="(project, pro) in projectsFilterd"
        v-bind:key="pro"
        class="row-project-client"
      >
        <v-col>
          <v-row no-gutters align="center">
            <v-col>
              <span class="d-block">Proyecto</span>
              <span class="extra-big-titles d-block">{{ project.name }}</span>
            </v-col>
            <v-col>
              <v-row justify="end" no-gutters>
                <v-col cols="auto">
                  <v-btn elevation="0" class="btn-files" tile :ripple="false"
                    ><span class="letter-spacing-0 not-text-transform"
                      >Archivos Generales</span
                    ></v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row no-gutters justify="space-between">
            <v-col cols="12">
              <span class="d-block mt-4"
                >Selecciona un espacio para obtener más información</span
              >
            </v-col>
            <v-col cols="12" sm="auto" class="card-spaces" v-for="(space, s) in project.spaceProject" v-bind:key="s">
                <v-card elevation="0" tile>
                    <v-img :src="'https://www.ofisdesigncms.actstudio.xyz/'+space.cover.path"></v-img>
                   <v-card-text class="Project--line-height-texts pt-3 pt-xl-5 pb-0 pb-xl-8 px-0">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <span class="titles aeonik-bold color-black">{{space.name}}</span>
                            </v-col>
                            <v-col cols="12" class="mt-3">
                                <p class="aeonik-regular color-black">
                                    {{space.description}}
                                </p>
                            </v-col>
                        </v-row>
                   </v-card-text>
                </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <span @click="logout">logout</span>
    <!-- <span>{{ user }}</span> -->
  </div>
</template>
<script>
export default {
  name: "ProjectsClients",
  props: {
    user: { type: Object, required: true },
  },
  computed: {
    projectsFilterd: function () {
      var projects = this.projects;
      //   var newProjects = []

      //   for (let i = 0; i < projects.length; i++) {
      //       const element = projects[i];
      //       console.log(element)
      //   }

      return projects;
    },
  },
  data() {
    return {
      sessionUser: "",
      projects: [],
    };
  },
  beforeCreate() {
    //para el login
    if (!this.$session.exists()) {
      this.$router.push("/login-admin");
    }

    //fetch de los proyectos
    fetch(
      "https://ofisdesigncms.actstudio.xyz/api/collections/get/ClientProjects?token=e96ec97538f5b51ad80b44386e6d1c",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          populate: 1,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.projects = res.entries))
      .catch((error) => console.log(error.message));
  },
  mounted() {
    this.$emit("update", 1);
  },
  methods: {
    logout: function () {
      this.$session.destroy();
      this.$emit("update");
      this.$router.push("/login-admin");
    },
  },
};
</script>